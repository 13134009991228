var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "order-requiring-attention-component" } },
    [
      _vm.currentField === "review"
        ? _c("review")
        : _c("item-requiring-attention"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }